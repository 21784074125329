/* eslint-disable import/no-anonymous-default-export */
// console.log(`NODE_ENV is set to: ${process.env.NODE_ENV}`);
export default {
  // apiurl: process.env.NODE_ENV === "development"
  // ? "http://cspi-ems-api.localhost.com/"
  // : process.env.NODE_ENV === "testing"
  // ? "hrmsstagingapi.cherrypiksoftware.com/" 
  // : "https://hrmsapi.cherrypiksoftware.com/",

  apiurl: process.env.NODE_ENV === "development"
    ? "http://cspi-ems-api.localhost.com/"
    // : "https://hrmsapi.cherrypiksoftware.com/",
  : "http://hrmsstagingapi.cherrypiksoftware.com/",
  // apiurl: process.env.NODE_ENV === "development"
  //   ? "http://cspi-ems-api.localhost.com/"
  //   : process.env.NODE_ENV === "production"
  //     ? "http://hrmsstagingapi.cherrypiksoftware.com/"
  //     : "https://hrmsapi.cherrypiksoftware.com/",
  authApis: {
    login: "api/auth/login",
    logout: "api/auth/logout",
    refreshToken: "api/auth/refresh",
    gmailotp: "api/send-otp",
    verifyotp: "api/verify-otp",
    resetpassword: "api/reset-password",
  },
  apis: {
    userList: "api/users",
    authUser: "api/users/me",
    userInTime: "api/emp/in-time",
    userOutTime: "api/emp/out-time",
    getPermission: "api/permission",
    createPermission: "api/permission",
    updatePermission: "api/permission/",
    deletePermission: "api/permission/remove/",
    getRole: "api/role",
    editRole: "api/roles/",
    createRole: "api/role",
    deleteRole: "api/role/remove/",
    deleteUser: "api/users/remove/",
    updateRole: "api/role/",
    updateImg: "api/upload",
    deleteImg: "api/remove-file",
    createUser: "api/users/create",
    editUser: "api/users/update/",
    singleUser: "api/users/",
    dureTime: "api/emp/totalDurationTime/",
    dureTime1: "api/emp/totalDurationTime/show/",
    holidayLeave: "api/holiday-leave",
    removeLeave: "api/holiday-leave/",
    eidtLeave: "api/holiday-leave/",
    singleLeave: "api/holiday-leave/",
    createTicket: "api/leave",
    EditTicket: "api/leave/update-status/",
    singleTicket: "api/leave/edit-status/",
    getAttandance: "api/users/get-working-duration/",
    AllgetAttandance: "api/working-duration/all-users/",
    getEvent: "api/event/",
    createEvent: "api/event",
    DeleteEvent: "api/event/",
    singleEvent: "api/event/",
    editEvent: "api/event/",
    removeImage: "api/removeEventImage/",
    createUpcomingEvent: "api/upcomingevent",
    uploadEventImg: "api/uploadEventFile",
    getAllEvent: "api/upcomingevent",
    getallAttendance: "api/user-monthly-report/",
    getAttendance: "api/monthly-report/",
    getdailylog: "api/user-daily-report/",
    getalldatadailylog: "api/all-users-daily-report/",
    AllDeleteEvent: "api/upcomingevent/",
    singleUpcomingEvent: "api/upcomingevent/",
    EditupcomingEvent: "api/upcomingevent/",
    deleteUpcomingImage: "api/removeEventFile",
    addSalary: "api/users/add-salary/",
    AllSalarydata: "api/user/salary/",
    AllSalaryPdf: "api/download-salary-slip",
    AllSalaryview: "api/view-salary-slip",
    createjob: "api/job/create",
    getAllJob: "api/job/show",
    deletejob: "api/job/delete/",
    singlejob: "api/job/show/",
    editjob: "api/job/update/",
    getQulification: "api/qualification/show",
    createQulification: "api/qualification/create",
    deleteQulification: "api/qualification/delete/",
    singleQulification: "api/qualification/show/",
    editQulification: "api/qualification/update/",
    getExperience: "api/experience/show",
    createExperience: "api/experience/create",
    deleteExperience: "api/experience/delete/",
    singleExp: "api/experience/show/",
    editExperience: "api/experience/update/",
    bdayNotification: "api/employeebdynotification/show",
    addTime: "api/timeEntry/addtime/",
    chekUserData: "api/timeEntry/show",
    approveTime: "api/timeEntry/updateStatus/",
    alldatasalary: "api/user/getalldatasalary",
    alldatasalaryid: "api/user/getalldatasalarybyid/",
    userstatus: "api/update/userstatus",
    offerletteradd: "api/offerLetter",
    getEmpleaves: "api/leave",
    getleaves: "api/allleave",
    EditTickets: "api/leave-ticket/",
    allinout: "api/unapproved-in-out-data",
    // offerletterdo: "api/offer-letterdo/",
    // offerletterview: "api/offer-letter-view-pdf/",
    // offerletterdelete: "api/offer-letters/",
    previewofferletter: "api/offer/previewofferletter/",
    Templatepostdata: "api/template",
    Templategetstatu: "api/update/templatestatus",
    Templategeedit: "api/templates/",
    Templateupdate: "api/templates/",
    Templatedelete: "api/template/",
    // Templatedit:"api/templates/",
    // Relievingletter
    Relievingletterget: "api/resign-letters",
    Templategetdata: "api/templates",
    offerletter: "api/offer/letter",
    Relievingletterpost: "api/resignLetter",
    Relievingletterdelete: "api/resign-letters/",
    Relievingletterdo: "api/download-resign-letter/",
    Relievingletterview: "api/view-resign-letter/",
    relivingletter: "api/resign-letter/update-status",
    Notification: "api/admin/notifications",
    NotificationEmployee: "api/employee/notifications",
    Notificationread: "api/notifications/",
    Notificationview: "api/notifications/view/",
    viewSalaryPdf: "api/view-salary-pdf",
    // getEmpleaves: "api/leave",
    // getleaves: "api/allleave",
    offerletterdo: "api/download-offer-letter/",
    offerletterview: "api/view-offer-letter/",
    offerletterdelete: "api/offer-letters/",
    offerlattertemplaye: "api/download-offer-letter/",
    // relivingletter: "api/resign-letter/update-status",
    // viewSalaryPdf: "api/view-salary-pdf",
    Salarydelete: "api/salary-slip/",
    Addsalaryall: "api/cron/add-salaryslip",
    calculateworking: "api/cron/calculate-working-hrs",
    cronlogs: "api/cron-logs",
    cronlogsdelete: "api/cron-logs/delete/",
    userimg: "api/logged-user-image",
    getpermissions: "api/get/permissions",
    // settings apis
    getnotificationicons: "api/get-notification-icons",
    savenotificationicons: "api/notification-icons",
    saveWorkingHours: "api/save-working-hours",
    getWorkingHours: "api/working-hours",
    saveleavebalance: "api/save-leave-balance",
    getleavebalance: "api/get-leave-balance",
    //view profile
    userimgid: "api/logged-user-image/",
    userreportAttendance: "api/user-report/",
    getUserReportByDateRange: "api/getUserReportByDateRange/",
    leavetickets: "api/leave-tickets/",
    Readedemployeenotifications: "api/employee/readednotifications",
    Readedadminnotifications: "api/admin/readednotifications",
    ShowMissingTimeEntriesById: "api/time-entries/",
    TimeEntrshowuser: "api/time-entry-showbyid/",
    Updartetimeentry: "api/time-entry/",
    deletetimeentry: "api/empinout/force-delete/",
    savesections: "api/save-sections-order",
    getsections: "api/get-sections-order",
    alllettersget: "api/letters/",
    addlettersFieldata: "api/letters/",
    dynamicletterdownload: "api/download",
    dynamicletterview: "api/view",
    dynamicletterdelete: "api/dynamicletters/",
    fetchUserRole: "api/current-user-role",
    toggleRole: "api/toggle-role/",
    salarydetailsdownload: "api/salary-details/download/",
    designationcreate: "api/designation/create",
    designationhow: "api/designation/show",
    designationdelete: "api/designation/delete/",
    Designationhowbyid: "api/designation/showbyid/",
    designationupdate: "api/designation/update/",
    Departmentcreate: "api/department/create",
    Departmentshow: "api/department/show",
    Departmentdelete: "api/department/delete/",
    Departmenthowbyid: "api/department/showbyid/",
    Departmentupdate: "api/department/update/",
    userleave: "api/getLeaveAndAbsentStatus",

    adminnotifiwise: "api/admin-read-type-wise/",
    employeenotifiwise: "api/employee-read-type-wise/",

    notificationsalladmin:"api/mark-fetched-admin-read",
    notificationsallemployee:"api/mark-fetched-employee-read"
  },
};
