import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Badge from "@mui/material/Badge";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Divider from '@mui/material/Divider';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import "./notification.css";
import Authapi from "../Services/Authapi";
import ls from "local-storage";
import swal from "sweetalert";
import * as FaIcons from 'react-icons/fa';
import * as MdIcons from 'react-icons/md';
import * as GiIcons from 'react-icons/gi';
// import * as AiIcons from 'react-icons/ai';
// import * as BiIcons from "react-icons/bi";
// import * as RiIcons from "react-icons/ri";
// import * as CgIcons from "react-icons/cg";
// import * as BsIcons from "react-icons/bs";
// import * as FiIcons from "react-icons/fi";
// import * as IoIcons from "react-icons/io5";
// import * as HiIcons from "react-icons/hi";
// import * as GrIcons from "react-icons/gr";
// import * as DiIcons from "react-icons/di";
import ClearAllIcon from '@mui/icons-material/ClearAll';

const allIcons = {
  ...FaIcons,
  ...MdIcons,
  ...GiIcons,
  // ...AiIcons,
  // ...BiIcons,
  // ...RiIcons,
  // ...CgIcons,
  // ...BsIcons,
  // ...FiIcons,
  // ...IoIcons,
  // ...HiIcons,
  // ...GrIcons,
  // ...DiIcons, 
  // Add more icon packs here if needed
};
const iconStyle = { color: '#1b6e95', marginRight: '8px' };

const defaultIcons = {
  "Leave Ticket": <i className="fas fa-ticket-alt" style={iconStyle}></i>,
  "Birthday": <i className="fas fa-birthday-cake" style={iconStyle}></i>,
  "Leave Status": <i className="fas fa-ticket-alt" style={iconStyle}></i>,
  "Upcoming Event": <i className="fa fa-arrow-right" style={iconStyle}></i>,
  "Resign-Letter": <i className="fa fa-sign-out" style={iconStyle}></i>,
  "Salary-Slip": <i className="fas fa-balance-scale" style={iconStyle}></i>,
  "Time-Entry": <i className="far fa-times-circle" style={iconStyle}></i>,
  "New Holiday": <i className="fas fa-sign-out-alt" style={iconStyle}></i>,
  "Time-Entry Status": <i className="far fa-times-circle" style={iconStyle}></i>,
};

function notificationsLabel(count) {
  if (count === 0) {
    return "no notifications";
  }
  if (count > 99) {
    return "more than 99 notifications";
  }
  return `${count} notifications`;
}

function formatDate(dateString) {
  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
    timeZone: "Asia/Kolkata"
  };
  return new Intl.DateTimeFormat("en-GB", options).format(new Date(dateString)).replace(/\//g, '-');
}

const NotificationComponent = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [notifications, setNotifications] = useState({});
  const [loading, setLoading] = useState(true);
  const [expandedGroups, setExpandedGroups] = useState({});
  const [notificationIcons, setNotificationIcons] = useState({});
  const history = useHistory();
  const User = ls("user");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    if (window.Notification && window.Notification.permission !== "granted") {
      window.Notification.requestPermission();
    }
    fetchData();
    const intervalId = setInterval(fetchData, 120000);

    return () => clearInterval(intervalId);
  }, []);


  const fetchData = async () => {
    setLoading(true);
    try {
      let notificationsData = ls("roles") === "Super Admin"
        ? await Authapi.Notification()
        : await Authapi.NotificationEmployee();
      setNotifications(notificationsData);

      const initialExpandedGroups = {};
      Object.keys(notificationsData).forEach(type => {
        initialExpandedGroups[type] = true;
      });
      setExpandedGroups(initialExpandedGroups);

      const iconsResponse = await Authapi.getNotificationIcons();
      if (iconsResponse) {
        const transformedIcons = {};
        Object.keys(iconsResponse).forEach(key => {
          transformedIcons[key.replace(/_/g, ' ')] = iconsResponse[key];
        });
        setNotificationIcons(transformedIcons);
      }

      if (notificationsData) {
        Object.keys(notificationsData).forEach(type => {
          notificationsData[type].forEach(async notification => {
            if (notification.is_view === 0) {
              showDesktopNotification(notification);
              await Authapi.Notificationview(notification.id);
            }
          });
        });
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNotificationClick = async (notification) => {
    try {
      await Authapi.Notificationread(notification.id);
      const notificationData = { id: notification.id, type: notification.type, dataId: notification.data_id };
      sessionStorage.setItem('fromNotificationPanel', 'true');
      switch (notification.type) {
        case "Leave Ticket":
        case "Leave Status":
          history.push({
            pathname: "/tickets",
            state: { notificationData }
          });
          break;
        case "Time-Entry":
          sessionStorage.setItem('fromNotificationPanel', 'true');
          history.push({
            pathname: "/check",
            state: { notificationData }
          });
          break;
        case "Time-Entry Status":
          sessionStorage.setItem('fromNotificationPanel', 'true');
          history.push({
            pathname: "/check",
            state: { notificationData }
          });
          break;
        case "Upcoming Event":
          const eventId = notification.data_id;
          sessionStorage.setItem('openEventModal', 'true');
          history.push({
            pathname: "/dashboard",
            state: { eventId }
          });
          break;
        case "New Holiday":
          const dateMatch = notification.notification.match(/\d{2}-\d{2}-\d{4}/);
          if (dateMatch) {
            const date = dateMatch[0];
            sessionStorage.setItem('openHolidayModal', 'true');
            history.push({
              pathname: "/leaves",
              state: { date }
            });
          }
          break;
        case "Resign-Letter":
          history.push("/Relievingletter");
          break;
        case "Birthday":
          history.push("/dashboard");
          break;
        case "Salary-Slip":
          sessionStorage.setItem('openSalaryModal', 'true');
          history.push({
            pathname: `/users/${notification.user_id}`,
            state: { tab: "salaryInfo", salarySlipId: notification.data_id }
          });
          break;
        default:
          break;
      }
      handleClose();
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };

  const handleExpandClick = (type) => {
    setExpandedGroups((prev) => ({
      ...prev,
      [type]: !prev[type],
    }));
  };

  const renderNotificationsByType = (type) => {
    return notifications[type].map((notification, index) => (
      <Card
        key={notification.id}
        onClick={() => handleNotificationClick(notification)}
        style={{
          marginBottom: '10px',
          cursor: 'pointer',
          transition: 'background-color 0.3s',
          position: 'relative',
        }}
        onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#f0f0f0'}
        onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'white'}
      >
        <CardContent>
          <Typography variant="body2" component="p">
            {index + 1}. {notification.notification}
          </Typography>
          <Typography variant="caption" color="textSecondary" style={{ position: 'absolute', bottom: '1px', left: '172px' }}>
            {formatDate(notification.created_at)}
          </Typography>
        </CardContent>
      </Card>
    ));
  };


  const userpermissions = (User && Array.isArray(User.roles) && User.roles[0] && Array.isArray(User.roles[0].permissions))
    ? User.roles[0].permissions.map(permissionObj => permissionObj.name)
    : [];

  const showDesktopNotification = (notification) => {
    if (window.Notification && window.Notification.permission === "granted") {
      const desktopNotification = new window.Notification("Cherrypik Software", {
        body: notification.notification,
        icon: "/logo.png",
      });
      desktopNotification.onclick = () => {
        window.focus();
        history.push({
          pathname: "/DetailedNotifications"
        })
      };
    }
  };

  const handleMarkAllAsRead = async () => {

    if (ls("roles") === "Super Admin") {
      try {
        const willProceed = await swal({
          title: "Are you sure?",
          text: "Do you want to mark all notifications as read?",
          icon: "warning",
          buttons: ["Cancel", "Confirm"],
          dangerMode: true,
        });

        if (willProceed) {
          swal({
            title: "Loading",
            text: "Please wait...",
            buttons: false,
            closeOnClickOutside: false,
            closeOnEsc: false,
            width: "10%",
            content: {
              element: "img",
              attributes: {
                src: "https://www.boasnotas.com/img/loading2.gif",
                style: "width: 50px;"
              }
            }
          });
          const response = await Authapi.notificationsalladmin();
          if (response.status === true) {
            fetchData();
            swal("Success", "All notifications marked as read!", "success");
          }
        } else {
          swal("Cancelled", "The action was cancelled.", "info");
        }
      } catch (error) {
        console.error("Error marking all notifications as read:", error);
        swal("Error", "Failed to mark all notifications as read.", "error");
      }
    } else {
      try {
        const willProceed = await swal({
          title: "Are you sure?",
          text: "Do you want to mark all notifications as read?",
          icon: "warning",
          buttons: ["Cancel", "Confirm"],
          dangerMode: true,
        });

        if (willProceed) {
          swal({
            title: "Loading",
            text: "Please wait...",
            buttons: false,
            closeOnClickOutside: false,
            closeOnEsc: false,
            width: "10%",
            content: {
              element: "img",
              attributes: {
                src: "https://www.boasnotas.com/img/loading2.gif",
                style: "width: 50px;"
              }
            }
          });
          const response = await Authapi.notificationsallemployee();
          if (response.status === true) {
            fetchData();
            swal("Success", "All notifications marked as read!", "success");
          }
        } else {
          swal("Cancelled", "The action was cancelled.", "info");
        }
      } catch (error) {
        console.error("Error marking all notifications as read:", error);
        swal("Error", "Failed to mark all notifications as read.", "error");
      }
    }
  }

  return (
    <div>
      <IconButton
        aria-describedby={id}
        aria-label={notificationsLabel(Object.keys(notifications).flatMap(key => notifications[key]).length)}
        style={{
          position: "absolute",
          right: "53px",
          top: "8px",
          color: "white",
        }}
        onClick={handleClick}
      >
        <Badge badgeContent={Object.keys(notifications).flatMap(key => notifications[key]).length} color="info">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            // padding: 2,
            borderRadius: 10,
          },
        }}
      >
        <div style={{ padding: '16px', backgroundColor: '#1b6e95', color: 'white', display: 'flex', alignItems: 'center' }}>
          <NotificationsActiveIcon style={{ marginRight: '8px' }} />
          <Typography variant="h6" style={{ margin: 0 }}>
            Notifications
          </Typography>
            <IconButton
            onClick={() => handleMarkAllAsRead()}
            style={{ color: "#fff", left: "130px"
            }}
            title="Clear Notifications"
          >
            <ClearAllIcon />
          </IconButton>
        </div>
        <Divider />
        <Typography sx={{ p: 2 }} style={{ height: "400px", width: "350px", overflowY: "auto" }}>
          {loading ? (
            <p>Loading...</p>
          ) : (
            Object.keys(notifications).length > 0 ? (
              Object.keys(notifications).map((type) => (
                <div key={type}>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '8px 0' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {allIcons[notificationIcons[type]]
                        ? React.createElement(allIcons[notificationIcons[type]], { style: iconStyle })
                        : defaultIcons[type]}
                      <Typography variant="subtitle1" style={{ margin: 0 }}>
                        {type}
                      </Typography>
                    </div>
                    {Object.keys(notifications).length > 1 && (
                      <IconButton onClick={() => handleExpandClick(type)}>
                        {expandedGroups[type] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </IconButton>
                    )}
                  </div>
                  {expandedGroups[type] && renderNotificationsByType(type)}
                  <Divider />
                </div>
              ))
            ) : (
              <p>No notifications available</p>
            )
          )}
        </Typography>
        <Divider />
        {userpermissions.includes("DetailedNotifications.Show") && (
          <div style={{ padding: '8px', textAlign: 'center' }}>
            <Link to="/DetailedNotifications" style={{ textDecoration: 'none' }}>
              <Typography variant="button" style={{ color: '#1b6e95' }}>
                Show All Notifications
              </Typography>
            </Link>
          </div>
        )}
      </Popover>
    </div>
  );
};

export default NotificationComponent;
